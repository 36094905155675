<template>
    <v-app>
        <v-row justify="center">
            <v-col
                cols="12"
                sm="10"
                md="8"
                lg="6"
                class="mt-10"
            >
                <v-card ref="form">
                    <v-card-text>
                        <v-text-field
                            color="purple darken-2"
                            label="Email"
                            required
                            v-model="form.identifier"
                        ></v-text-field>
                        <v-text-field
                            color="purple darken-2"
                            label="Password"
                            required
                            type="password"
                            v-model="form.password"
                        ></v-text-field>
                    </v-card-text>
                    <v-card-actions>
                        <v-btn
                            color="primary"
                            text
                            @click="submit"
                        >
                            Submit
                        </v-btn>
                    </v-card-actions>
                </v-card>

            </v-col>

        </v-row>
    </v-app>
</template>
<script>
import { mapActions } from "vuex";
export default {
    name: "AuthHome",
    data() {
        return {
            form: {
                identifier: "",
                password: "",
            },
            showError: false
        };
    },
    methods: {
        ...mapActions(["LogIn"]),
        async submit() {
            const User = new FormData();
            User.append("identifier", this.form.identifier);
            User.append("password", this.form.password);

            try {
                await this.LogIn(User);
                await this.$router.push('/home');
                this.showError = false
            } catch (error) {
                this.showError = true
                console.log("GOT ERROR : " + error)
            }
        }
    }
}
</script>
